import { Paper, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { darkTheme, lightTheme } from "./Theme";
import Routes from "./Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
// main app component
function App() {
  const [darkMode, setDarkMode] = useState(false);
  const storeDark = localStorage.getItem("darkMode");

  useEffect(() => {
    if (storeDark === "true") {
      setDarkMode(true);
      document.body.classList.add("dark-mode");
    } else {
      setDarkMode(false);
      document.body.classList.remove("dark-mode");
    }
  }, [storeDark]);

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Paper>
        <Routes />
      </Paper>

      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
