/* eslint-disable array-callback-return */
import {
    Box,
    Typography,
    TextField,
    IconButton,
    Container,
    Divider,
    Grid,
    Link,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { useTheme } from "@emotion/react";
import { t } from "i18next";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { setCategories } from "../../redux/Categories"
import api from "../../API/Fetch_data_Api";
import slugify from "slugify";

const Footer = () => {
    const theme = useTheme();
    const categories = useSelector((state) => state.Categories)?.categories;
    const settings = useSelector((state) => state.Settings)?.settings;

    const dispatch = useDispatch();

    const [category, setCategory] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await api.get_category();
                setCategory(result.data);
                dispatch(setCategories(result.data));
            } catch (error) {
                console.log("Error fetching categories:", error);
            }
        };

        if (categories.length === 0) {
            fetchData();
        } else {
            setCategory(categories);
        }
    }, [categories, dispatch]);


    return (
        <>
            <div className="footer">
                <>
                    {/* useful */}
                    <Container className="mainContainer">
                        <Box
                            display={{ xs: "flex", md: "flex" }}
                            marginBottom={2}
                            justifyContent={"space-between"}
                            alignItems={"start"}
                            flexDirection={{ xs: "column", md: "row" }}
                        >
                            <Box>
                                <Typography fontWeight="bold">
                                    {t("useful_categories")}
                                </Typography>
                                <Divider />
                                <Box mt={2} display={"flex"} sx={{ flexDirection: { xs: "column", md: "row" } }}>
                                    <Grid container maxWidth={"sm"} spacing={2}>
                                        {category?.map((e) => {

                                            return (
                                                <Grid item xs={6} key={e.id}>
                                                    <NavLink key={e.id}
                                                        to={`/categories/${e.id}/${slugify(e.name, { lower: true })}`}
                                                        className="footer-item"
                                                    >
                                                        {e.name}
                                                    </NavLink>
                                                </Grid>
                                            )

                                        })}
                                    </Grid>
                                </Box>
                            </Box>
                            <Box marginTop={{ xs: 10, md: 0 }}>
                                <Typography fontWeight="bold">{t("quick_links")}</Typography>
                                <Divider />
                                <Box display={{ xs: "flex", md: "flex" }} flexDirection={"column"} mt={2}>
                                    <Box display={"flex"} flexDirection={"column"} >
                                        <NavLink
                                            className="footer-item"
                                            to={"/"}
                                        >
                                            {t("home")}
                                        </NavLink>

                                        <NavLink
                                            to={"/about"}
                                            className="footer-item"
                                        >
                                            {t("about_us")}
                                        </NavLink>

                                        <NavLink
                                            to={"/categories"}
                                            className="footer-item"
                                        >
                                            {t("category")}
                                        </NavLink>

                                    </Box>
                                    <Box display={"flex"} flexDirection={"column"} >
                                        <NavLink
                                            to={"/providers"}
                                            className="footer-item"
                                        >
                                            {t("providers")}
                                        </NavLink>

                                        <NavLink
                                            to={"/contact"}
                                            className="footer-item"
                                        >
                                            {t("contact")}
                                        </NavLink>
                                        <NavLink to={"/terms-and-conditions"} className="footer-item">
                                            {t("terms")}
                                        </NavLink>
                                        <NavLink to={"/privacy-policies"} className="footer-item">
                                            {t("privacy")}
                                        </NavLink>
                                    </Box>
                                </Box>
                            </Box>
                            <Box marginTop={{ xs: 10, md: 0 }} sx={{ zIndex: 1 }}>
                                {/* <Typography fontWeight="bold">{t("keep_up_with_us")}</Typography>
                            <Divider /> */}
                                <Typography fontSize={"16px"} fontWeight={"bold"} component={"div"} sx={{ my: 1, letterSpacing: "0.8px !important" }}>
                                    {t("follow_us")}
                                </Typography>
                                
                                <Divider sx={{ width: "100%", borderColor: "gray",border: '1px solid' }} />
                                
                                <Box display={"none"}>
                                    <TextField
                                        fontSize={"20px"}
                                        sx={{
                                            background: theme.palette.background.box,
                                            mb: 2
                                        }}
                                        className="textfield-footer"
                                        placeholder="Enter Email"
                                        size="small"
                                        type="email"
                                        InputProps={{
                                            endAdornment: <SendIcon sx={{ cursor: "pointer" }} />,
                                        }}
                                        width={{ xs: "400px", md: "600px" }}
                                    ></TextField>
                                </Box>
                                <Box mt={2}>
                                    <Buttons />
                                </Box>
                            </Box>
                        </Box>
                    </Container>

                    <Divider sx={{background: 'gray',height: '2px'}} />
                    <Container sx={{ display: "flex", alignItems: "center", justifyContent: "center",borderTop: '' }} maxWidth="lg" className="footer-container mainContainer">
                        <Box>
                            {t("copyright")} &#169; {dayjs().year()}

                        </Box>
                        <Box>
                            {settings?.general_settings?.support_name}.
                        </Box>
                        <Box>
                            {t("rights")}
                        </Box>
                    </Container>
                </>
            </div>
        </>
    );
};

export default Footer;

const Buttons = () => {

    const settings = useSelector((state) => state.Settings)?.settings;

    let web_settings = settings.web_settings;
    let social_media = web_settings?.social_media;


    return (
        <Box display={"flex"} alignItems={"center"} gap={1.5} maxWidth={500}>

            {social_media?.map((e, index) => {
                return (
                    <Link
                        key={index}
                        href={e.url}
                        target='_blank'
                        size="small"
                        sx={{
                            zIndex: 1,
                            color: "black",
                            "&:hover": {
                                color: "white",
                            },
                            maxWidth: "30px",
                        }}
                    >
                        <Box maxWidth={"100%"}>
                            <Box component={'img'}
                                src={e.file}
                                width={"100%"}
                                borderRadius={"50%"}
                            />
                        </Box>
                    </Link>
                )
            })}
        </Box>
    );
};
